// Polyfills for IE 11.

// Add fetch and Promise polyfills.
// https://caniuse.com/fetch
import 'whatwg-fetch'
import Promise from 'promise-polyfill';
if (!window.Promise) {
  window.Promise = Promise;
}

// Adds missing functionality to Array and Class.
// Stimulus 3+ does not support IE 11.
import "@stimulus/polyfills"

