import { Controller } from "@hotwired/stimulus"
import * as Honeybadger from '@honeybadger-io/js'

export default class extends Controller {
  static targets = [ "product", "studentsCount", "total" ]
  connect() {
    this.priceFields = document.querySelector('#new_account .price_fields')
    if (this.priceFields) {
      this.priceFields.classList.add('hide')
    }
    this.element.parentNode.classList.remove('hide')
    this.calculate()
  }

  calculate() {
    let url = this.element.getAttribute("data-url")
    fetch(url, {
          method: 'POST',
          credentials: "same-origin",
          headers: this.requestHeaders(),
          body: new FormData(this.element)
        })
        .then(response => response.json()
        )
        .then(json => {

          if(json.hasOwnProperty('total')) { // eslint-disable-line no-prototype-builtins
            this.totalTarget.innerHTML = this.displayAsMoney(json['total'])
            document.querySelector('.price-calculator__total-label').classList.remove('hide');
            this.element.parentNode.classList.remove('hide')
            if (this.priceFields) {
              this.copyValues()
            }
          }
      }).catch(function(error) {
        console.log(error);
        Honeybadger.notify(error);
      });
  }

  requestHeaders() {
    const headers = {}
    const csrfToken = this.getMetaValue("csrf-token")
    if(csrfToken){
      headers["X-CSRF-Token"] = csrfToken
    }
    return headers
  }

  copyValues() {
    document.getElementById('account_students_count'   ).value = parseInt(document.getElementById('calculator_students_count').value)
    document.getElementById('account_pinboard_enabled' ).checked = document.getElementById('calculator_pinboard_enabled').checked
    document.getElementById('account_planboard_enabled').checked = document.getElementById('calculator_planboard_enabled').checked
    document.getElementById('account_portfolio_enabled').checked = document.getElementById('calculator_portfolio_enabled').checked
  }

  // TODO replace with Rails.csrfToken() once we switch to rails-ujs
  getMetaValue(name) {
    const element = document.head.querySelector(`meta[name="${name}"]`)
    if (element) {
      return element.getAttribute("content")
    }
  }

  displayAsMoney(amount) {
    return '&euro; ' + amount.toFixed(2).replace(/\./g , ",");
  }

}

