import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  connect() {
    let timeoutID = setTimeout(() => {
      clearTimeout(timeoutID)
      this.loadIframes()
    }, 2000)
  }

  loadIframes() {
    let src = this.element.getAttribute('data-src');
    if(src) {
      this.element.setAttribute('src', src);
    }
  }
}

