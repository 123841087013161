import { Controller } from "@hotwired/stimulus"
import QrScanner from 'qr-scanner'

// Connects to data-controller="qr-scan"
export default class extends Controller {
  static targets = [ 'video', 'button', 'success' ]

  connect() {
    console.log("Initializing QR Scanner")
    this.qrScanner = new QrScanner(
      this.videoTarget,
      this.readQr(this),
      { // See for details : https://github.com/nimiq/qr-scanner#2-create-a-qrscanner-instance
        highlightScanRegion:  true,
        highlightCodeOutline: true,
        maxScansPerSecond:    10
      },
    );
  }

  disconnect() {
    this.qrScanner.stop();
    this.qrScanner.destroy();
  }

  scan() {
    console.log("Starting QR scanner")
    this.qrScanner.start();
    this.buttonTarget.classList.add('hide')
    this.videoTarget.parentElement.classList.remove("hide")
  }

  readQr(controller) {
    return function(result) {
      console.log("QR code found")
      controller.disconnect();
      controller.videoTarget.parentElement.classList.add("hide")
      controller.successTarget.classList.remove("hide")
      let url = new URL(result.data)
      let path = url.pathname + url.search
      setTimeout(function() { window.location.href = path }, 1500)
    }
  }
}
