import { application } from "./application"

import DeferredIframesController from "./deferred_iframes_controller.js"
application.register("deferred-iframes", DeferredIframesController)

import PriceCalculatorController from "./price_calculator_controller.js"
application.register("price-calculator", PriceCalculatorController)

import QrScanController from "./qr_scan_controller"
application.register("qr-scan", QrScanController)

